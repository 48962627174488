import axios from 'axios'
import _ from 'lodash'
const Cookie = require('js-cookie')

const storage = {
  cookie: {
    get: Cookie.get,
    set: Cookie.set,
    remove: Cookie.remove,
  },
  localStorage: {
    get: window.localStorage.getItem.bind(window.localStorage),
    set: window.localStorage.setItem.bind(window.localStorage),
  },
}

const loadImageAsync = (imgsrc, callBack) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = () => {
      callBack()
      resolve()
    }
    image.onerror = () => {
      reject(new Error(`Could not load image at ${imgsrc}`))
    }
    image.src = imgsrc
  })
}


const scrollCtrl = (position) => { 
  window.scroll({ top: position, left: 0, behavior: 'smooth'});
}

const createFormdata = (data) => {
  // eslint-disable-next-line no-undef
  const formData = new FormData()
  _.each(data, (o, k) => {
    if (_.isObject(o) && k !== 'file') {
      o = JSON.stringify(o)
    }
    formData.append(k, o)
  })
  return formData
}

const request = (opts) => {
  console.log("request", opts)
  return axios({
    ...opts,
    timeout: 20000, // 20s
  }).then((response) => {
    console.log("request success", response)
    let { statusText, status, data } = response
    if (data.code === 200 ) {
      Promise.resolve()
    } else { 
      statusText = data.message
      status = data.status
      return Promise.reject({ success: false, statusCode: status, message: statusText })
    }
    
  }).catch((error) => {
    console.log("request failed", error)
    const { response } = error
    let msg
    let statusCode
    if (response && response instanceof Object) {
      const { data, statusText } = response
      statusCode = response.status
      msg = data.message || statusText
    } else {
      statusCode = 600
      msg = error.message || 'Network Error'
    }
    return Promise.reject({ success: false, statusCode, message: msg })
  })
}




export { storage, loadImageAsync, scrollCtrl, request, createFormdata }