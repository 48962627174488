import React from 'react';
import ReactDOM from 'react-dom';
import enUS from "./locale/en_US";
import zhCN from "./locale/zh_CN";
// import moment from "moment";
// import "moment/locale/zh-cn";
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css'; 
import 'normalize.css'; 
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

let locale = zhCN

ReactDOM.render(
  <App locale={locale} />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
