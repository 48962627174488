import React from "react";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import Switch from "@material-ui/core/Switch";
// import { Select } from "@material-ui/core";
import { Form, Button, Input, Select, Tabs, Switch, Row, Col } from "antd";
import logo from "./static/banner.png";
import "./App.css";
import { storage, scrollCtrl, request, createFormdata } from "./lib";

// const TencentCaptcha = new TencentCaptcha(AppId)
const { TabPane } = Tabs;

const domian = "http://g.cn.miaozhen.com";
const Api = "http://services.miaozhen.com/api/mb";
const AppId = "2060073642";

function a11yProps(id) {
  return {
    value: id,
    // 'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const isTrack = storage.cookie.get("opt");
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { sm: { offset: 8, span: 8 }, xs: { span: 8, offset: 0 } },
};
class App extends React.Component {
  state = {
    currentTab: "tab1",
    checked: +isTrack && +isTrack === 1,
    tipText: "",
    loading: false,
    tipClass: "success",
    formData: {
      deviceType: "",
      deviceId: "",
    },
  };

  handleTabChange = (newValue) => {
    if (this.state.currentTab !== newValue) {
      // 清空表单
      this.refs.form && this.refs.form.resetFields();
      this.setState({
        currentTab: newValue,
        tipText: "",
      });
      if (newValue === "tab2") {
        const timer = setTimeout(() => {
          scrollCtrl(document.body.clientHeight);
          clearTimeout(timer);
        }, 20);
      }
    }
  };

  handleSwitchChange = (checked) => {
    console.log("handleSwitchChange", checked);
    if (checked) {
      storage.cookie.set("opt", 1, { domain: "nequal.com" });
      storage.cookie.remove("a", { domain: "nequal.com" });
    } else {
      storage.cookie.remove("opt", { domain: "nequal.com" });
    }
    this.setState({
      checked,
    });
  };

  summitForm = async (values) => {
    console.log(this.state.formData, values, window);
    if (window.TencentCaptcha) {
      const TencentCaptcha = new window.TencentCaptcha(AppId, (res) => {
        if (res.ret === 0) {
          // 验证成功
          this.setState({
            loading: true,
          });
          request({
            url: Api,
            method: "POST",
            data: createFormdata(values),
            headers: {
              "Content-Type": "",
              ticket: res.ticket, //腾讯验证码成功后的返回参数
              randStr: res.randstr, //腾讯验证码成功后的返回参数
              ...values, // 业务参数
            },
          })
            .then(() => {
              console.log("summitForm success");
              this.refs.form && this.refs.form.resetFields();
              // message.success('申请提交成功，感谢配合！')
              this.setState({
                tipText: "申请提交成功，感谢配合！",
                tipClass: "success",
              });
              const timer = setTimeout(() => {
                this.setState({
                  tipText: "",
                  tipClass: "success",
                });
                clearTimeout(timer);
              }, 2000);
            })
            .catch((error) => {
              console.log("summitForm failed");
              this.setState({
                tipText: error.message,
                tipClass: "error",
              });
            })
            .finally(() => {
              this.setState({
                loading: false,
              });
            });
        }
      });
      TencentCaptcha.show();
    }
  };

  formChange = (e, formKey) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [formKey]: formKey === "deviceType" ? e : e.target.value,
      },
    });
  };

  render() {
    const { checked, currentTab, tipText, tipClass, loading } = this.state;
    const { locale } = this.props
    return (
      <div className="App" id="AppContainer">
        <header className="App-header">
          <img src={logo} className="img-responsive" alt="logo" />
        </header>
        <Tabs
          defaultActiveKey={currentTab}
          onChange={this.handleTabChange}
          className="tabs-container"
        >
          <TabPane tab={locale.tabs.browser} key="tab1">
            <div className="container text-container">
              <p>{locale.browserText.h1}</p>
              <p>{locale.browserText.h2}</p>
              <p>{locale.browserText.h3}</p>
              <p style={{ marginTop: "2rem", fontWeight: "bold" }}>
                {locale.browserText.h4}
                <Switch
                  checked={checked}
                  onChange={this.handleSwitchChange}
                  name="checkedA"
                />
              </p>
            </div>
          </TabPane>
          <TabPane tab={locale.tabs.mobile} key="tab2">
            <div className="container text-container">
              <p>{locale.mobileText.h1}</p>
              <p>{locale.mobileText.h2}</p>
              <p>{locale.mobileText.h3}</p>
              <ul className="tips">
                <li>{locale.mobileText.h4}</li>
                <li>{locale.mobileText.h5}</li>
              </ul>
              <Form
                {...layout}
                ref="form"
                name="basic"
                initialValues={{ deviceType: "", deviceId: "" }}
                className="form-container"
                onFinish={this.summitForm}
              >
                <Form.Item
                  label={locale.form.item1}
                  name="deviceType"
                  rules={[{ required: true, message: `${locale.form.message1}` }]}
                >
                  <Select
                    placeholder={locale.form.placeholder1}
                    onChange={(e) => this.formChange(e, "deviceType")}
                    autoFocus
                  >
                    <Select.Option value="1">Android{locale.form.device}</Select.Option>
                    <Select.Option value="2">iOS{locale.form.device}</Select.Option>
                    <Select.Option value="3">Windows Phone {locale.form.device}</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label={locale.form.item2}
                  name="deviceId"
                  rules={[{ required: true, message: `${locale.form.message2}` }]}
                >
                  <Input
                    placeholder={locale.form.placeholder2}
                    onChange={(e) => this.formChange(e, "deviceId")}
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type="danger" htmlType="submit" loading={loading}>
                    {locale.form.buttonText}
                  </Button>
                  {tipText ? (
                    <span className={`tip ${tipClass}`}>{tipText}</span>
                  ) : null}
                </Form.Item>
              </Form>
            </div>
          </TabPane>
        </Tabs>
        <Row className="footer_bottom">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 9 }}
            lg={{ span: 10 }}
            className="belong"
          >
            {locale.footer.item1}
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 12 }}
            className="cert"
          >
            <a href="http://www.beian.miit.gov.cn/ " target="_blank" rel="noreferrer">
              {locale.footer.item2}
            </a>
            |
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802028464"
              target="_blank" rel="noreferrer"
            >
              {locale.footer.item3}
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default App;
