/* eslint-disable no-template-curly-in-string */
var typeTemplate = '${label} is not a valid ${type}';
var localeValues = {
  locale: "zh-cn",
  tabs: {
    browser: "浏览器",
    mobile: "移动设备",
  },
  browserText: {
    h1: "多年以来，基于大数据及人工智能技术，秒针系统以丰富的数据维度，为企业提供包括线上测量、线下测量、内容测量在内的全域测量解决方案。同时，秒针在为企业级品牌客户提供服务时，有可能收集到用户的终端信息数据。秒针高度重视每一位用户的个人信息安全，并采取多项措施确保用户数据以合规、加密的方式进行存储、传输、计算、删除。",
    h2: "用户有权选择不参与秒针对用户终端信息的获取及分析，只需要简单的一步操作就可完成该设置。",
    h3: "对于浏览器，您可以直接通过点击下方的“停止在此浏览器上标识我”选项，来打开或关闭秒针在此浏览器上对您的标识：",
    h4: "停止在此浏览器上标识我：",
  },
  mobileText: {
    h1: "多年以来，基于大数据及人工智能技术，秒针系统以丰富的数据维度，为企业提供包括线上测量、线下测量、内容测量在内的全域测量解决方案。同时，秒针在为企业级品牌客户提供服务时，有可能收集到用户的终端信息数据。秒针高度重视每一位用户的个人信息安全，并采取多项措施确保用户数据以合规、加密的方式进行存储、传输、计算、删除。",
    h2: "用户有权选择不参与秒针对用户终端信息的获取及分析，只需要简单的一步操作就可完成该设置。",
    h3: "对于移动设备，您可以通过在下方填写您的设备ID，来申请关闭秒针在该设备上对您的标识。",
    h4: "Android设备的设备ID通常是Android ID或OAID，根据您设备品牌的不同，设备ID的具体获知方式可能有所不同。",
    h5: "iOS设备的设备ID是IDFA，请在Appstore中下载可查看IDFA的应用，使用应用获知您设备的IDFA。",
  },
  form: {
    item1: "设备类型",
    item2: "设备ID",
    placeholder1: "请选择",
    placeholder2: "请输入",
    buttonText: "提交",
    device: "设备",
    message1: "请选择设备类型!",
    message2: "请输入设备ID!",
  },
  footer: {
    item1: "© 2022恩亿科 版权所有",
    item2: "京ICP备16022188号-3",
    item3: "京公网安备11010802028464号",
  },
};
export default localeValues;